import React from "react"
import { Section, Container } from "../Containers"
import { Text, Image } from "../Core"
import { ImageText } from "../ImageText"
import { Button } from "../Buttons"
import ImageSlider from "../ImageSlider/ImageSlider"

const BodySections = ({ sections = [] }) => {
  return (
    <>
      {sections.map((section, i) => {
        const type = section.type

        return (
          <Section colorBack={section.colorBack} key={i}>
            <Container
              xsmall={section.container == "xsmall"}
              small={section.container == "small"}
              medium={section.container === "medium"}
              large={section.container === "large"}
              xlarge={section.container === "xlarge"}
              full={section.container === "full"}>
              {type === "text" && (
                <>
                  <Text text={section.text} />
                  {section.buttons && Array.isArray(section.buttons) && (
                    <div className="button-group">
                      {section.buttons.map(({ button }) => (
                        <Button
                          key={button.href}
                          buttonText={button.buttonText}
                          href={button.href}
                          appearance={button.appearance}
                          destination={button.destination}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}

              {type === "anchor" && (
                <Text
                  as={section.element}
                  text={section.text}
                  id={section.id}
                />
              )}

              {type === "gallery" && (
                <div className="even-grid--2">
                  {section.images.map(image => (
                    <Image key={image} publicId={image} />
                  ))}
                </div>
              )}

              {type === "imageText" && (
                <ImageText
                  image={section.imageText.image}
                  text={section.imageText.text}
                />
              )}

              {type === "imageSlider" && (
                <div className="offset-slider">
                  <ImageSlider images={section.images} />
                </div>
              )}
            </Container>
          </Section>
        )
      })}
    </>
  )
}

export default BodySections
